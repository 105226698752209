import { graphql } from 'gatsby'
import React from 'react'
import { Row, Col } from 'react-bootstrap'

import {
  Blanket,
  BlanketBackground,
  BlanketTitle,
} from '../../components/Blanket'
import { BoxLinks } from '../../components/BoxLinks'
import { SEO } from '../../components/Seo'
import { Section } from '../../components/Section'

import * as classes from './societa.module.scss'

const links = [
  { to: '/società/organigramma', name: 'Organigramma' },
  { to: '/società/la-storia', name: 'La storia' },
  { to: '/società/hall-of-fame', name: 'Hall of Fame' },
]

export default function SocietaPage({ data }) {
  return (
    <Blanket title={'Società'} fullWidth>
      <SEO title={'Società'} />

      <BlanketBackground>
        <BlanketTitle>Società</BlanketTitle>

        <BoxLinks links={links} />
        <Section className={classes.section}>
          <Row className={classes.smallPadding}>
            <Col xs={12} sm={12} md={3} lg={3}>
              <p className={classes.downloadTitle}>Download</p>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <div className={classes.downloadButton}>
                <a
                  href={data.brandManual.localFile.publicURL}
                  target={'_blank'}
                >
                  Brand Manual
                </a>
              </div>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <div className={classes.downloadButton}>
                <a href={data.logo.localFile.publicURL} target={'_blank'}>
                  Logo
                </a>
              </div>
            </Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <div className={classes.downloadButton}>
                <a
                  href={data.logoNegativo.localFile.publicURL}
                  target={'_blank'}
                >
                  Logo negativo
                </a>
              </div>
            </Col>
          </Row>

          <Row className={classes.smallPadding}>
            <Col xs={12} sm={12} md={3} lg={3}></Col>
            <Col xs={12} sm={4} md={3} lg={3}>
              <div className={classes.downloadButton}>
                <a
                  href={data.codiceEtico.localFile.publicURL}
                  target={'_blank'}
                >
                  Codice etico
                </a>
              </div>
            </Col>
          </Row>
        </Section>
      </BlanketBackground>
    </Blanket>
  )
}

export const query = graphql`
  query Societa {
    brandManual: wordpressWpMedia(title: { eq: "brand-manual" }) {
      localFile {
        publicURL
      }
    }
    codiceEtico: wordpressWpMedia(
      title: { eq: "Codice etico Pallacanestro Reggiana" }
    ) {
      localFile {
        publicURL
      }
    }
    logo: wordpressWpMedia(title: { eq: "logo-colore" }) {
      localFile {
        publicURL
      }
    }
    logoNegativo: wordpressWpMedia(title: { eq: "logo-negativo" }) {
      localFile {
        publicURL
      }
    }
  }
`
